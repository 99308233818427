<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('nav.dashboard')"
    />

    <div v-if="showDashboard">
      <div class="mb-5 financial_card_container grid-3">


        <financial-card
            :number="getDashboardContent.total_customers"
            :title="$t('financial.card.admin.total_customers')"
            icon="f-clients-2-icon"
            :is_price="false"
            route="users-clients"
        />
        <financial-card
            :number="getDashboardContent.total_vendors"
            :title="$t('financial.card.admin.total_vendors')"
            icon="f-companies-icon"
            :is_price="false"
            route="users-vendors"
        />
        <financial-card
            :number="getDashboardContent.total_drivers"
            :title="$t('financial.card.admin.total_drivers')"
            icon="f-orders2-icon"
            :is_price="false"
            route="users-drivers"
        />
        <div></div>

        <financial-card
            :number="getDashboardContent.total_orders"
            :title="$t('financial.card.total_orders')"
            icon="f-orders-icon"
            :is_price="false"
            route="orders"
        />

        <financial-card
            :number="getDashboardContent.overall_total_sales"
            :title="$t('financial.card.total_sales')"
            icon="f-sales-icon"
            route="financial-all"
        />

        <financial-card
            :number="getDashboardContent.overall_total_profit"
            :title="$t('financial.card.total_profit')"
            icon="f-profit-plus-icon"
            route="financial-all"
        />

        <div></div>
      </div>

      <div class="mb-5 home_echart_flex ">
        <echart-bar
            class="w-4"
            :data="getDashboardContent.orders_data"
            @changeFilter="orderStaticsFilter"
        />
        <echart-pie
            class="w-2"
            :males="getDashboardContent.users.males"
            :females="getDashboardContent.users.females"
            :uncompleted="getDashboardContent.users.uncompleted"

            @changeFilter="userStaticsFilter"
        />
      </div>

      <div class="mb-5 home_echart_flex ">

        <div class="chart_card w-2-r">
          <div class="chart__heading mb-5">
            <div class="chart__heading-title">{{ $t('chart.companies.title') }}</div>
            <div class="chart__heading-subtitle">{{ vendorFilterTitle }}</div>
            <b-dropdown
                variant="background"
                class="chart-options"
                toggle-class="text-decoration-none rounded-10 px-4 py-4 "
                menu-class="rounded-8"
                no-caret
                dropleft
            >
              <template v-slot:button-content>
                <vertical-dots-icon/>
              </template>

              <b-dropdown-item @click="()=>changeVendorFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
              </b-dropdown-item>

              <b-dropdown-item @click="()=>changeVendorFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
              </b-dropdown-item>

              <b-dropdown-item @click="()=>changeVendorFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
              </b-dropdown-item>


            </b-dropdown>
          </div>

          <template v-if="getDashboardContent.popular_vendors.length>0">
            <chart-tile
                v-for="(item, index) in getDashboardContent.popular_vendors"
                :key="index"
                :color="vendorsColors[index]"
                :style="index===4?'margin-bottom: 0;':''"
                :total="getDashboardContent.total_popular_vendors"
                :data="item"/>
          </template>
          <wameed-no-data v-else/>
        </div>


        <echart-line
            class="w-4-r"
            :data="getDashboardContent.sales_data"
            @changeFilter="orderSaleFilter"
        />
      </div>


      <div class="d-flex flex-wrap flex-1" v-if="getDashboardContent.pending_orders">

        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column w-table-min_unset">
            <div style="padding:16px 26px 0"
                 class=" d-flex justify-content-between align-content-center flex-wrap">
              <span class="text-med-22">{{ $t('home.orders.title') }}</span>
              <router-link :to="{name:'orders'}" class="text-reg-20">{{
                  $t('home.orders.btn')
                }}
              </router-link>
            </div>
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getDashboardContent.pending_orders"
                :items="getDashboardContent.pending_orders"
                :fields="fields"
                :custimized-items="[
                { name: 'date' },
                { name: 'vendor' },
                { name: 'service_type' },
                { name: 'action' },
                { name: 'price' },
                { name: 'status' },
                { name: 'rate' },
              ]"
            >
              <template slot="date" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{ formatDate(data.item.date) }}</span>
                </div>
              </template>

              <template slot="vendor" slot-scope="{ data }">
                <check-value :value="data.item.vendor"/>
              </template>

              <template slot="service_type" slot-scope="{ data }">
                <check-value :value="data.item.service_type"/>
              </template>


              <template slot="price" slot-scope="{ data }">
                <check-price
                    :price="Number(data.item.price)+Number(data.item.service_fee)"
                    :dis-price="Number(data.item.price)+Number(data.item.service_fee)-Number(data.item.dis_price)"
                />
              </template>

              <template slot="rate" slot-scope="{ data }">
                <customer-rate :rate="data.item.rate"/>
              </template>

              <template slot="status" slot-scope="{ data }">
                <custom-badge :text="data.item.status_name" :color="data.item.status_color"/>
              </template>


              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      :to="toToDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>


            </w-tables>
          </div>
        </b-col>

      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import FinancialCard from "@/components/financialCard";

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import EchartPie from "@/views/pages/dashboard/charts/echart-pie";
import EchartLine from "@/views/pages/dashboard/charts/echart-line";
import EchartBar from "@/views/pages/dashboard/charts/echart-bar";
import ChartTile from "@/views/pages/dashboard/components/chartTile";
import {WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CheckPrice from "@/components/checkPrice";
import CustomerRate from "@/components/customerRate";
import StatusBadge from "@/components/statusBadge";
import {statues as orderStatues} from "@/enums/orderStatus.enum"
import CustomBadge from "@/components/customBadge";
import CheckValue from "@/components/checkValue";

export default {
  components: {
    CheckValue,
    CustomBadge,
    orderStatues,
    WTables,
    StatusBadge,
    CheckPrice,
    CustomerRate,
    EchartBar,
    EchartPie,
    EchartLine,
    ChartTile,
    FinancialCard,
    PageHeader
  },
  data() {
    return {
      "vendorsColors": [
        "#00C0F3",
        "#28C76F",
        "#FF9F43",
        "#7367F0",
        "#FED029"
      ],

      fields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'no',
          label: this.$i18n.t('orders.table.no'),
          sortable: true,
        },
        {
          key: 'user_name',
          label: this.$i18n.t('orders.table.user_name'),
          sortable: true,
        },
        {
          key: 'driver_name',
          label: this.$i18n.t('orders.table.driver_name'),
          sortable: true,
        },
        {
          key: 'date',
          label: this.$i18n.t('orders.table.date'),
          sortable: true,
        },
        {
          key: 'vendor',
          label: this.$i18n.t('orders.table.place'),
          sortable: true,
        },
        {
          key: 'service_type',
          label: this.$i18n.t('orders.table.type'),
          sortable: true,
        },
        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('orders.table.status'),
          sortable: true,
        },
        {
          key: 'rate',
          label: this.$i18n.t('orders.table.rate'),
          sortable: true,
        },

        {
          key: 'action',
          label: this.$i18n.t('table.options')
        },
      ],

      vendorFilter: null,
      filterContent: {},
    };
  },
  computed: {
    ...mapGetters({
      getDashboardContent: 'admin/home/getData',
      getProfileInfo: 'profile/getBasic',
      userPermissions: 'profile/getPermissions'
    }),
    showDashboard() {
      return this.userPermissions.includes('read home')
    },
    reviews() {
      return this.getDashboardContent.reviews.slice(0, 4);
    },

    vendorFilterTitle() {
      if (this.vendorFilter) {
        const {value, type} = this.vendorFilter;
        return this.$i18n.t('chart.last_' + value + '_' + type);


      }
      return this.$i18n.t('chart.last_12_months');
    },
  },
  methods: {
    changeVendorFilter(value, type) {

      this.vendorFilter = {value, type};

      this.filterContent.vendors_value = value;
      this.filterContent.vendors_type = type;

      this.loadData();
    },

    userStaticsFilter(value) {
      this.filterContent.users_value = value.value;
      this.filterContent.users_type = value.type;

      this.loadData();
    },
    orderSaleFilter(value) {
      this.filterContent.order_sales_value = value.value;
      this.filterContent.order_sales_type = value.type;

      this.loadData();
    },
    orderStaticsFilter(value) {
      this.filterContent.order_statics_value = value.value;
      this.filterContent.order_statics_type = value.type;

      this.loadData();
    },
    loadData() {

      this.$store.dispatch('admin/home/get', this.filterContent);
    },
    toToDetail(id) {
      return {
        name: 'order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    }
  },
  created() {
    this.loadData();
  },

};
</script>
